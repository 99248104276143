<template>
  <div id="FestivalShowsAccessControl">
    <QsCard
      :qlik-ids="['DWkSm']"
      style="grid-column: 1 / span 24"
      card-style="compact"
    />
    <card style="grid-column: 1 / span 12">
      <qs-kpi qlik-id="UGYgSph" class="single-kpi" />
    </card>
    <card style="grid-column: 13 / span 12">
      <qs-kpi qlik-id="NHUrmP" class="single-kpi" />
    </card>

    <QsCard :qlik-ids="['ZpYrx']" style="grid-column: 1 / span 16" />
    <QsCard :qlik-ids="['jnXEvN']" style="grid-column: 17 / span 8" />

    <QsCard :qlik-ids="['pkbRr']" style="grid-column: 1 / span 8" />
    <QsCard :qlik-ids="['NceT']" style="grid-column: 9 / span 16" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsCard from "@/components/Qlik/QsCard";
import QsKpi from "@/components/Qlik/QsKpi";

export default { components: { Card, QsCard, QsKpi } };
</script>

<style scoped>
#FestivalShowsAccessControl {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 45px 100px repeat(2, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
.single-kpi {
  font-size: 22px;
}
</style>
